<template>
  <div id="report">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />事件上报
        </van-col>
        <van-col
          span="14"
          class="header-title"
          style="text-align: right"
          @click="onReportClick"
        >
          确认上报
        </van-col>
      </van-row>
    </div>

    <div class="report-body">
     <van-row style="margin-bottom:20px;">
        <van-col span="12">选择类型</van-col>
        <van-col
          span="12"
          style="text-align: right;font-weight:bold;color:#436ef3"
          @click="showTypes = true"
        >
          {{ type_name }}
         
          <van-icon name="arrow-down" />
         
        </van-col>
      </van-row>
      <van-row v-if="type_name">
        <van-col span="12">选择出团</van-col>
        <van-col
          span="12"
          style="text-align: right"
          @click="showTravelLists = true"
        >
          {{ report.travel_list_name }}
         
          <van-icon name="arrow-down" />
         
        </van-col>
      </van-row>

      <van-row class="reply-container">
        <van-col span="24">
          <textarea
            class="reply-text-area"
            v-model="report.report_content"
            placeholder="请输入上报内容"
            name=""
            id=""
            cols="20"
            rows="5"
          ></textarea>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24">
          <div class="report-text">上传附件</div>
          <div>
            <van-uploader
              max-count="1"
              v-model="fileList"
              :before-delete="onDeleteImageUrl"
              
              preview-full-image
              accept="image/*"
              :after-read="fnUploadRequest"
            />
          </div>
        </van-col>
      </van-row>
      <div class="btn" @click="onReportClick">确认上报</div>
    </div>
    <van-popup
      v-model="showTravelLists"
      closeable
      close-icon="close"
      :style="{ height: '42%' }"
      position="bottom"
      ><div class="select-container">
        <div class="select-title">选择出团</div>
        <div
          class="select-item"
          v-for="(i, index) in travel_lists"
          :key="index"
          @click="onSelect(i)"
        >
          {{ i.list_name
          }}<span style="color: #888">({{ i.status_text }})</span>
        </div>
        <van-empty v-if="travel_lists.length==0" image="error" description="暂无出团可选" />
      </div>
    </van-popup>
     <van-popup
      v-model="showTypes"
      closeable
      close-icon="close"
      :style="{ height: '42%' }"
      position="bottom"
      ><div class="select-container">
        <div class="select-title">选择类型</div>
        <div
        
          class="select-item"
          v-for="(i, index) in types"
          :key="index"
          @click="onTypeSelect(i)"
        >
          {{ i.type_name
          }}
        </div>
        <van-empty v-if="types.length==0" image="error" description="暂无类型可选" />
      </div>
    </van-popup>
    <!-- <Tarbar :active="2"></Tarbar> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import Tarbar from "../components/Tarbar.vue";
import oss from "@/utils/oss";
export default {
  name: "trip",
  components: {
    Tarbar,
  },
  data() {
    return {
      plans: {},
      active: 1,
      show: false,
      fileList: [],
      travel_lists: [],
      item: {
        replies: [],
      },
      showTravelLists: false,
      showTypes:false,
      type_name:"",
      loading: true,
      finished: false,
      refreshing: false,
      report: {
        report_type:"",
        report_content: "",
        travel_list_name: "",
        travel_list_id: "",
        attachment_url: "",
      },
      types:[
        {
          id:1,
          type_name:"出团事件"
        },
        {
          id:0,
          type_name:"疫情事件"
        },
        {
          id:2,
          type_name:"其他"
        }
      ],
      query: {
        page: 1,
        plan_name: "",
      },
      showPopover: false,
      actions: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onDeleteImageUrl() {
      this.report.image_url = "";
      this.fileList = [];
    },
    onSelect(item) {
      this.report.travel_list_id = item.id;
      this.report.travel_list_name = item.list_name;
      this.showTravelLists = false;
    },
    onTypeSelect(i){
      this.type_name = i.type_name
      this.report.report_type = i.id
      this.showTypes = false
    },
    async onReportClick() {
      if(this.report.report_type===""){
        Toast('请选择上报类型')
        return false
      }
      // if (this.report.travel_list_id == ""&&this.report.report_type==1) {
      if (this.report.travel_list_id == "") {
        Toast("请关联出团");
        return false;
      }
      if (this.report.report_content == "") {
        Toast("请填写上报内容");
        return false;
      }
      const data = {
        emergency: {
          report_type: this.report.report_type,
          report_content: this.report.report_content,
          travel_list_id: this.report.travel_list_id,
          attachment_url: this.report.attachment_url,
        },
      };
      const res = await this.$apis.post_emergency(data);
      if (res.status == 200) {
        Toast("上报成功");
        this.$router.go(-1);
      }
    },
    async select_travel_lists() {
      const res = await this.$apis.select_travel_lists();
      if (res.status == 200) {
        const actions = [];
        this.travel_lists = res.data;
        // res.data.forEach(element => {
        //     actions.push({
        //         text:element.list_name,
        //         id:element.id
        //     })
        // });
        // this.actions = actions
      }
    },
    fnUploadRequest(option) {
      option.status = "uploading";
      option.message = "上传中...";
      const _self = this;
      // Toast.loading({
      //   message: "上传中...",
      //   forbidClick: true,
      // });
      oss.ossUploadFile(option).then((res) => {
        _self.report.attachment_url = res.fileUrl[0].split("?")[0];
        option.status = "success";
        // option.message = '上传中...';
        _self.fileList = [
          {
            url: res.fileUrl[0].split("?")[0],
          },
        ];
        Toast.clear();
      });
    },
  },
  created() {
    this.select_travel_lists();
  },
};
</script>

<style scoped>
#report{
    height: 100vh;
    padding-top:10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.report-body {
  height: 85vh;
  width: 90%;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
  position: relative;
  background-color: #fff;
  font-size: 0.3rem;
}
.reply-container {
  margin-top: 20px;
}
.reply-text-area {
  border-radius: 0.2rem;
  background: #f3f3f3;
  width: 100%;
  padding: 0.3rem;
}
.report-text {
  height: 1rem;
  line-height: 1rem;
  /* border-top: 1px dashed #f3f3f3; */
  font-size: 14px;
  color: #323233;
  padding-left: 0.3rem;
  /* font-weight: bold; */
}
.select-container {
  height: 100%;
  background: #fff;
}
.select-item {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  height: 0.8rem;
  line-height: 0.8rem;
}
.select-item:nth-child(even) {
  background: #f4f3f3;
}
.select-item:nth-child(odd) {
  background: #fff;
}
.select-title {
  font-size: 0.3rem;
  text-align: center;
  font-weight: bold;
  height: 0.8rem;
  line-height: 0.8rem;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
